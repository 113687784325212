<template>
  <div class="batchBox">
    <el-row class="accountCon">
      <el-col class="leftCon" :span="4">
        <div class="label1">
          <i class="el-icon-collection-tag" /> 关联信息
          <img src="@/assets/images/triangle.png" alt />
        </div>
      </el-col>
      <el-col class="rightCon" :span="20">
        <el-form
          ref="detail"
          label-position="right"
          label-width="140px"
          :rules="rules"
          :model="detail"
          class="formBox"
        >
          <el-form-item label="部门序号" prop="deptno">
            <el-input
              v-model="detail.deptno"
              class="w300"
              :disabled="isShop"
              maxlength="150"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="归属市场" prop="marketId">
            <el-select
              v-model="detail.marketId"
              class="w300"
              clearable
              :disabled="isShop"
              @change="changeMarket"
              placeholder="请选择市场"
            >
              <el-option
                v-for="item in options.market_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="大区归属" prop="regionId">
            <el-select
              v-model="detail.regionId"
              class="w300"
              clearable
              :disabled="isShop"
              @change="changeRegion"
              placeholder="请选择大区"
            >
              <el-option
                v-for="item in options.region_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="门店类型" prop="type">
            <el-select
              v-model="detail.type"
              class="w300"
              clearable
              :disabled="isShop"
              placeholder="请选择门店类型"
              @change="changeType"
            >
              <el-option
                v-for="item in options.type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="门店属性" prop="shopType">
            <el-select
              v-model="detail.shopType"
              class="w300"
              clearable
              :disabled="shopType"
              placeholder="请选择门店属性"
            >
              <el-option
                v-for="item in options.shopType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="是否特殊门诊" prop="isSign">
            <el-radio-group v-model="detail.isSign" :disabled="isShop">
              <el-radio :label="1">否</el-radio>
              <el-radio :label="2">是</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否川渝门店" prop="isSichuan">
            <el-radio-group v-model="detail.isSichuan" :disabled="isShop">
              <el-radio :label="1">否</el-radio>
              <el-radio :label="2">是</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="detail.isSign == 2" label="关联市场" prop="relationMarketId">
            <el-select
              v-model="detail.relationMarketId"
              class="w300"
              clearable
              :disabled="isShop"
              placeholder="请选择关联市场"
              @change="changeRelationMarketId"
            >
              <el-option
                v-for="item in options.market_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-if="detail.isSign == 2" label="关联店铺" prop="relationShop">
            <el-select
              v-model="detail.relationShop"
              class="w300"
              clearable
              multiple
              filterable
              :disabled="isShop"
              placeholder="请选择关联店铺"
            >
              <el-option
                v-for="item in options.relationShop"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="门店金蝶编码" :prop="detail.type==1?'keycode':''">
            <el-input :disabled="isShop" v-model="detail.keycode" class="w300" placeholder="直营店必填" />
          </el-form-item>
          <el-button
            type="primary"
            size="mini"
            class="mainBtn"
            @click="submitForm('detail')"
          >下一步，填写店铺信息</el-button>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  readAdminMarketList,
  readAdminShopTypeList,
  readAdminRegionList,
  getShopList
} from "@/api/shop";
class Detail {
  deptno = "";
  marketId = "";
  regionId = "";
  type = "";
  shopType = "";
  isSign = 1;
  isSichuan = 1;
  marketName = "";
  marketKingdeeCode = "";
  regionName = "";
  relationMarketId = "";
  relationMarketName = "";
  relationShop = [];
  typeName = "";
  keycode = "";
}
class Rules {
  deptno = [{ required: true, message: "请填写部门序号", trigger: "blur" }];

  marketId = [{ required: true, message: "请选择归属市场", trigger: "change" }];

  regionId = [{ required: true, message: "请选择归属大区", trigger: "change" }];
  type = [{ required: true, message: "请选择门店类型", trigger: "change" }];

  shopType = [{ required: true, message: "请选择门店属性", trigger: "change" }];
  isSign = [
    { required: true, message: "请选择是否特殊门店", trigger: "change" }
  ];
  isSichuan = [
    { required: true, message: "请选择是否川渝门店", trigger: "change" }
  ];
  relationMarketId = [
    { required: true, message: "请选择关联市场", trigger: "change" }
  ];
  relationShop = [
    { required: true, message: "请选择关联店铺", trigger: "change" }
  ];
  keycode = [
    { required: true, message: "请填写门店金蝶编码", trigger: "blur" }
  ];
}
class Options {
  constructor() {
    this.market_id = [];
    this.region_id = [];
    this.type = [];
    this.shopType = [
      {
        id: "1",
        name: "门诊"
      },
      {
        id: "2",
        name: "门店"
      },
      {
        id: "3",
        name: "直管"
      }
    ];
    this.relationShop = [];
  }
}
export default {
  name: "Relevance",
  props: {
    typePage: {
      type: String,
      default: ""
    },
    dataMsg: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      detail: new Detail(),
      rules: new Rules(),
      options: new Options(), // 选项
      active: 0,
      shopType: false,
      isShop: false
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getOptions();
  },
  methods: {
    // 选项列表
    getOptions() {
      readAdminMarketList().then(res => {
        this.options.market_id = res.list;
      });
      readAdminShopTypeList().then(res => {
        this.options.type = res.list;
      });
      readAdminRegionList({ page: 1, limit: 10000 }).then(res => {
        this.options.region_id = res.list;
      });

      if (this.dataMsg.id) {
        this.detail = this.dataMsg;
        this.detail.type = parseInt(this.dataMsg.type);
        this.shopType = this.detail.shopType ? true : false;
        this.detail.marketId = parseInt(this.dataMsg.marketId);
        this.detail.regionId = parseInt(this.dataMsg.regionId);
        this.detail.isSign = parseInt(this.dataMsg.isSign);
        this.detail.isSichuan = parseInt(this.dataMsg.isSichuan);
        this.detail.relationMarketId = parseInt(this.dataMsg.relationMarketId);
        this.changeRelationMarketId(this.detail.relationMarketId);

        this.isShop = this.userInfo.shop_id ? true : false;
      } else {
        this.detail = this.dataMsg;
        this.isShop = false;
      }
    },

    // 改变市场
    changeMarket(e) {
      let that = this;
      that.options.market_id.forEach(function(val) {
        if (e == val.id) {
          that.detail.marketName = val.name;
          that.detail.marketKingdeeCode = val.kingdeeCode;
        }
      });
    },
    // 改变大区
    changeRegion(e) {
      let that = this;
      that.options.region_id.forEach(function(val) {
        if (e == val.id) {
          that.detail.regionName = val.name;
        }
      });
    },
    //改变关联市场
    changeRelationMarketId(e) {
      let that = this;
      that.options.market_id.forEach(function(val) {
        if (e == val.id) {
          // that.detail.relationMarketId = val.kingdeeCode;
          that.detail.relationMarketName = val.name;
        }
      });
      this.getShop(e);
    },
    // 获取店铺
    getShop(id) {
      getShopList({ page: 1, limit: 100000, marketId: id }).then(res => {
        this.options.relationShop = res.list;
        if (this.detail.relationShop) {
          let that = this;
          let newShop = [];
          that.options.relationShop.forEach(function(val) {
            that.dataMsg.relationShop.forEach(function(item) {
              if (item == val.shopId) {
                newShop.push(val.shopId);
              }
            });
          });
          that.detail.relationShop = newShop;
        }
      });
    },
    // 改变类型
    changeType(e) {
      let that = this;
      that.options.type.forEach(function(val) {
        if (e == val.id) {
          that.detail.typeName = val.name;
        }
      });
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.detail.type == 1 && !this.detail.keycode) {
            return this.$message({
              message: "直营店必须填写金蝶编码哦~",
              type: "warning"
            });
          }
          this.$emit("setActive", true);
          this.$emit("getData1", this.detail);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.accountCon {
  background: #fff;
  .leftCon {
    background: #fcfcfc;
    border-right: 1px solid #eee;
    position: absolute;
    height: 100%;
    > div {
      line-height: 48px;
      color: #fff;
      background: #9ea7b4;
      width: 100%;
      padding-left: 80px;
      font-size: 14px;
      box-sizing: border-box;
      img {
        position: absolute;
        right: -30px;
        width: 40px;
        height: 48px;
      }
    }
    .label1 {
      position: absolute;
      top: 40px;
    }
    .label2 {
      position: absolute;
      top: 270px;
    }
  }
  .rightCon {
    padding: 40px 0 0 18%;

    .mainBtn {
      margin: 30px auto 180px;
    }
  }
}
.formBox {
  display: flex;
  flex-wrap: wrap;
  .el-form-item {
    width: 50%;
  }
}
</style>
