<template>
  <div class="batchBox">
    <el-row class="accountCon">
      <el-col class="leftCon" :span="4">
        <div class="label1">
          <i class="el-icon-collection-tag" /> 店 铺 资 料
          <img src="@/assets/images/triangle.png" alt />
        </div>
      </el-col>
      <el-col class="rightCon" :span="24">
        <el-form
          ref="detail"
          label-position="right"
          label-width="140px"
          :rules="rules"
          :model="detail"
          class="formBox"
        >
          <el-form-item label="门店状态:" prop="status">
            <el-radio-group v-model="detail.status" :disabled="userInfo.shop_id">
              <el-radio :label="1">运营中</el-radio>
              <el-radio :label="3">永久关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="detail.status > 1 "
            label="关闭原因:"
            :prop="detail.status == 3?'reason':''"
          >
            <el-input v-model="detail.reason" class="w300" maxlength="150" show-word-limit />
          </el-form-item>

          <el-form-item v-if="detail.status == 2 " label="临时闭店时间" prop="closeEndAt">
            <el-date-picker
              v-model="closeTime"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="changeClose"
            />
          </el-form-item>
          <el-form-item label="门店名称" prop="shopName">
            <el-input
              v-model="detail.shopName"
              :disabled="userInfo.shop_id"
              class="w300"
              maxlength="150"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="开店时间" prop="openAt">
            <el-date-picker
              v-model="detail.openAt"
              type="date"
              :disabled="userInfo.shop_id"
              class="w300"
              value-format="yyyy-MM-dd"
              placeholder="请选择开店时间"
            />
          </el-form-item>

          <el-form-item label="仓库" prop="storeId">
            <el-select
              v-model="detail.storeId"
              class="w300"
              clearable
              :disabled="userInfo.shop_id"
              placeholder="请选择门店仓库"
              @change="changestoreId"
            >
              <el-option
                v-for="item in options.storeId"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="营业时间" prop="endTime">
            <el-time-select
              v-model="detail.startTime"
              class="w150"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '24:00'
              }"
              placeholder="每日开始时间"
            />
            <el-time-select
              v-model="detail.endTime"
              class="w150"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '24:00'
              }"
              placeholder="每日结束时间"
            />
          </el-form-item>
          <el-form-item label="门店电话" prop="shopPhone">
            <el-input v-model="detail.shopPhone" class="w300" placeholder="请填写门店电话" />
          </el-form-item>
          <el-form-item label="上午班" >
            <el-time-select
              v-model="detail.amStartTime"
              class="w150"
              :picker-options="{
                start: '09:00',
                step: '00:30',
                end: '18:30'
              }"
              placeholder="上午班开始时间"
            />
            <el-time-select
              v-model="detail.amEndTime"
              class="w150"
              :picker-options="{
                start: '09:00',
                step: '00:30',
                end: '18:30'
              }"
              placeholder="上午班结束时间"
            />
          </el-form-item>
          <el-form-item label="下午班" >
            <el-time-select
              v-model="detail.pmStartTime"
              class="w150"
              :picker-options="{
                start: '09:00',
                step: '00:30',
                end: '18:30'
              }"
              placeholder="下午班开始时间"
            />
            <el-time-select
              v-model="detail.pmEndTime"
              class="w150"
              :picker-options="{
                start: '09:00',
                step: '00:30',
                end: '18:30'
              }"
              placeholder="下午班结束时间"
            />
          </el-form-item>
          <el-form-item label="银行卡号" prop="bankCard">
            <el-input
              v-model="detail.bankCard"
              :disabled="userInfo.shop_id"
              class="w300"
              placeholder="请填写门店银行卡号"
            />
          </el-form-item>
          <el-form-item label="所在地区" prop="countryId">
            <region
              v-if="locality"
              :compile="userInfo.shop_id"
              :detail="region"
              @getRegion="getRegion"
            />
          </el-form-item>
          <el-form-item label="详细地址" prop="address">
            <el-input v-model="detail.address" class="w300" placeholder="请填写详细地址" />
          </el-form-item>

          <el-form-item label="面积" prop="area">
            <el-input v-model="detail.area" class="w300" clearable>
              <span slot="append">㎡</span>
            </el-input>
          </el-form-item>
          <el-form-item label="月租" prop="monthlyFee">
            <el-input v-model="detail.monthlyFee" class="w300" clearable>
              <span slot="append">元</span>
            </el-input>
          </el-form-item>
          <el-form-item label="床位" prop="bedNum">
            <el-input v-model="detail.bedNum" class="w300" clearable></el-input>
          </el-form-item>
          <el-form-item label="简介">
            <el-input
              v-model="detail.introduce"
              type="textarea"
              class="w300"
              maxlength="150"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="detail.remark"
              type="textarea"
              class="w300"
              maxlength="150"
              show-word-limit
            />
          </el-form-item>
          <div class="btnBox">
            <el-button
              type="primary"
              plain
              size="mini"
              class="btn"
              @click="submitForm2('detail')"
            >上一步，填写关联信息</el-button>
            <el-button
              v-if="typePage != 'compile'"
              type="primary"
              size="mini"
              class="mainBtn"
              @click="submitForm('detail')"
            >下一步，填写店长信息</el-button>
            <el-button
              v-else
              type="primary"
              size="mini"
              class="mainBtn"
              @click="submitForm3('detail')"
            >完成，编辑店铺资料</el-button>
          </div>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import region from "@/components/Region";
import { readAdminStoreList } from "@/api/shop";
class Detail {
  status = 1;
  shopName = "";
  reason = "";
  closeStartAt = "";
  closeEndAt = "";

  openAt = "";
  startTime = "";

  storeId = "";
  storeName = "";
  storeKingdeeCode = "";
  endTime = "";
  shopPhone = "";
  bankCard = "";
  provinceId = "";
  provinceName = "";
  cityId = "";
  cityName = "";
  countryId = "";
  countryName = "";
  address = "";
  introduce = "";
  remark = "";
  area = "";
  monthlyFee = "";
  bedNum = "";
  amStartTime="";
  amEndTime="";
  pmStartTime="";
  pmEndTime="";
  // am = {
  //   startTime:"",
  //   endTime:"",
  // };
  // pm = {
  //   startTime:"",
  //   endTime:"",
  // };
}
class Rules {
  status = [{ required: true, message: "请选择门店状态", trigger: "change" }];
  reason = [{ required: true, message: "请填写关闭原因", trigger: "blur" }];
  shopName = [{ required: true, message: "请填写门店名称", trigger: "blur" }];
  closeEndAt = [
    { required: true, message: "请填写临时闭店时间", trigger: "blur" }
  ];

  openAt = [{ required: true, message: "请选择开店时间", trigger: "change" }];

  endTime = [{ required: true, message: "请选择营业时间", trigger: "change" }];

  storeId = [{ required: true, message: "请选择仓库", trigger: "change" }];

  shopPhone = [{ required: true, message: "请填写门店电话", trigger: "blur" }];
  bankCard = [
    { required: true, message: "请填写门店银行卡号", trigger: "blur" }
  ];
  countryId = [
    { required: true, message: "请选择所在地区", trigger: "change" }
  ];
  address = [{ required: true, message: "请填写详细地址", trigger: "blur" }];
  area = [
    {
      pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
      message: "最多保留两位小数",
      trigger: "change"
    }
  ];

  monthlyFee = [
    {
      pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
      message: "最多保留两位小数",
      trigger: "change"
    }
  ];
}
class Options {
  constructor() {
    this.storeId = [];
  }
}
export default {
  name: "Relevance",
  components: {
    region
  },
  props: {
    typePage: {
      type: String,
      default: ""
    },
    dataMsg: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      detail: new Detail(),
      rules: new Rules(),
      options: new Options(), // 选项
      locality: false,
      closeTime: "",
      region: {
        province_id: "",
        city_id: "",
        district_id: ""
      }
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getOptions();
    if (!this.dataMsg.id) this.locality = true;
  },
  methods: {
    // 获取选项
    getOptions() {
      readAdminStoreList({ page: 1, limit: 100000 }).then(res => {
        this.options.storeId = res.list;
      });
      if (this.dataMsg.id) {
        this.detail = this.dataMsg;

        this.detail.storeId = parseInt(this.dataMsg.storeId);
        this.detail.status = parseInt(this.dataMsg.status);

        if(!this.dataMsg.amWork)
        {
          this.$set(this.detail,'amStartTime','09:00');
          this.$set(this.detail,'amEndTime','18:30');
        }else{
          var am = this.dataMsg.amWork.split("-");
          // this.detail.amStartTime=am[0];
          // this.detail.amEndTime=am[1];
          this.$set(this.detail,'amStartTime',am[0]);
          this.$set(this.detail,'amEndTime',am[1]);
        }

        if(!this.dataMsg.pmWork)
        {
          this.$set(this.detail,'pmStartTime','09:00');
          this.$set(this.detail,'pmEndTime','18:30');
        }else{
          var pm = this.dataMsg.pmWork.split("-");
          // this.detail.pmStartTime=pm[0];
          // this.detail.pmEndTime=pm[1];
          this.$set(this.detail,'pmStartTime',pm[0]);
          this.$set(this.detail,'pmEndTime',pm[1]);
        }

        console.log(this.detail);
        const { provinceId, cityId, countryId } = this.dataMsg;
        this.region = {
          province: {
            id: parseInt(provinceId)
          },
          city: {
            id: parseInt(cityId)
          },
          district: {
            id: parseInt(countryId)
          }
        };
        this.region.province_id = parseInt(provinceId) || "";
        this.region.city_id = parseInt(cityId) || "";
        this.region.district_id = parseInt(countryId) || "";
        this.locality = true;
      } else {
        this.detail = this.dataMsg;
      }
    },
    // 改变临时关闭时间
    changeClose(e) {
      this.detail.closeEndAt = e[0];
      this.detail.closeStartAt = e[1];
    },

    //改变仓库
    changestoreId(e) {
      let that = this;
      that.options.storeId.forEach(function(val) {
        if (e == val.id) {
          that.detail.storeName = val.name;
          that.detail.storeKingdeeCode = val.kingdeeCode;
        }
      });
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$emit("setActive", true);
          this.$emit("getData2", this.detail);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 店铺资料提交
    submitForm3(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if(this.detail.amStartTime&&this.detail.amEndTime)
          {
            this.detail.amWork=this.detail.amStartTime+'-'+this.detail.amEndTime;
          }
          if(this.detail.pmStartTime&&this.detail.pmEndTime)
          {
            this.detail.pmWork=this.detail.pmStartTime+'-'+this.detail.pmEndTime;
          }
          this.$emit("getData2", this.detail);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 关联信息
    submitForm2(formName) {
      this.$emit("setActiveUp", true);
    },
    // 获取地区
    getRegion(region) {
      const {
        province_id,
        province_name,
        city_id,
        city_name,
        district_id,
        district_name
      } = region;
      this.detail.provinceId = province_id;
      this.detail.provinceName = province_name;
      this.detail.cityId = city_id;
      this.detail.cityName = city_name;
      this.detail.countryId = district_id;
      this.detail.countryName = district_name;
    }
  }
};
</script>

<style lang="scss" scoped>
.accountCon {
  .leftCon {
    background: #fcfcfc;
    border-right: 1px solid #eee;
    position: absolute;
    height: 100%;
    > div {
      line-height: 48px;
      color: #fff;
      background: #9ea7b4;
      width: 100%;
      padding-left: 80px;
      font-size: 14px;
      box-sizing: border-box;
      img {
        position: absolute;
        right: -30px;
        width: 40px;
        height: 48px;
      }
    }
    .label1 {
      position: absolute;
      top: 40px;
    }
    .label2 {
      position: absolute;
      top: 270px;
    }
  }
  .rightCon {
    height: 100%;
    overflow-y: auto;
    padding: 40px 0 0 260px;
    .btn {
      margin: 20px 0 50px 0px;
    }
    .mainBtn {
      margin: 30px 0 0 80px;
    }
  }
  .btnBox {
    width: 86%;
    text-align: center;
  }
  .formBox {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      width: 50%;
    }
  }
  ::v-deep .el-input--small .el-input__inner {
    width: 100px !important;
  }
}
</style>
